<template>
	<!-- 合同详情 -->
	<div class="wrapper">
		<div class="top-title">
			<span>合同凭证</span>
		</div>

		<div class="contract-wrap">
			<el-card shadow="hover">
				<div slot="header" class="clearfix">
					<span>E签宝合同</span>
				</div>
        <div class="signed-file-list">
          <el-button
              v-for="item in esignFileList"
              :key="item.fileUrl"
              type="primary"
              size="mini"
              @click="downloadContract(item)"
          >{{ item.fileName }}</el-button
          >
        </div>
			</el-card>

			<el-card shadow="hover" v-if="!isShowReport || zhzFileList.length > 0">
				<div slot="header" class="clearfix">
					<span>租好租合同</span>
				</div>
				<span v-for="item in zhzFileList" :key="item.fileUrl">
					<el-button
						type="primary"
						size="mini"
						@click="downloadContract(item)"
						>{{ item.fileName }}</el-button
					>
				</span>
				<span
					class="report-btn"
					@click="goCreditReport"
					v-checkbtn="isShowBtn(AUTH_BTN.order_list_credit_report)"
				>
					<el-button
						v-if="!isShowReport"
						:disabled="isShowReport"
						type="primary"
						size="mini"
						>征信报告</el-button
					>
				</span>
			</el-card>

			<el-card shadow="hover" v-if="contractDetail.phxNotaryFile">
				<div slot="header" class="clearfix">
					<span>凤凰公证</span>
				</div>
				<div class="contract-item">
					<span>客户视频核验</span>
					<XgPlayer
						class="vedio-player"
						:url="contractDetail.phxNotaryFile.videoUrl"
					/>
				</div>
				<div class="contract-item" style="margin-top: 10px;">
					<span>已签署附件列表</span>
					<div class="signed-file-list">
						<el-button
							v-for="item in contractDetail.phxNotaryFile.signedFileList"
							:key="item.fileUrl"
							type="primary"
							size="mini"
							@click="downloadContract(item)"
							>{{ item.fileName }}</el-button
						>
					</div>
				</div>
        <div v-if="contractDetail.phxNotaryFile && contractDetail.phxNotaryFile.certificateUrl.length > 0" class="cert-item">
          <span>电子公证书</span>
          <el-button
              type="primary"
              size="mini"
              @click="downloadCertificate(contractDetail.phxNotaryFile.certificateUrl)"
          >下载</el-button
          >
        </div>
			</el-card>
		</div>
		<!--    <div class="contract-btn">-->
		<!--      <div class="contract-row">-->
		<!--        <span class="title">租好租公证</span>-->
		<!--      </div>-->
		<!--      <div class="contract-row">-->
		<!--        <span>公证链接</span>-->
		<!--        <span>-->
		<!--          <el-button :disabled="isDisabledNotaryUrl" type="primary" size="mini" @click="getNotaryUrl">获取</el-button>-->
		<!--        </span>-->
		<!--      </div>-->
		<!--      <div class="contract-row">-->
		<!--        <span>公证证书</span>-->
		<!--        <span>-->
		<!--          <el-button :disabled="isDisabledNotaryFile" type="primary" size="mini" @click="downloadNotary">下载</el-button>-->
		<!--        </span>-->
		<!--      </div>-->
		<!--    </div>-->

		<!--    <div class="contract-btn">-->
		<!--      <div class="contract-row">-->
		<!--        <span>征信报告</span>-->
		<!--        <span class="report-btn" @click="goCreditReport" v-checkbtn="isShowBtn(AUTH_BTN.order_list_credit_report)">-->
		<!--          <el-button v-if="!isShowReport" :disabled="isShowReport" type="primary" size="mini">查看</el-button>-->
		<!--          <div v-else class="report_null">查看</div>-->
		<!--        </span>-->
		<!--      </div>-->
		<!--    </div>-->
		<!--    <div style="height:50px;"></div>-->

		<!--  -->
		<!--    <el-dialog title="公证链接" :visible.sync="dialogVisible" width="700px">-->
		<!--      <div class="link">-->
		<!--        <span>公证链接：</span>-->
		<!--        <span>{{ notaryLink }}</span>-->
		<!--        <span>-->
		<!--          <el-button type="primary" size="mini" @click="copyLink">复制</el-button>-->
		<!--        </span>-->
		<!--      </div>-->
		<!--    </el-dialog>-->
	</div>
</template>

<script>
import { dateFormat } from "@/utils/index.js";
import { getContractDetailAPI, getNotaryApplyAPI, downloadPDF } from "./api";
import { authBtnMixin } from "@/mixins/authBtnMixin";
import XgPlayer from "@/components/xgplayer";

export default {
	name: "ContactDetail",
	mixins: [authBtnMixin],
	components: { XgPlayer },
	data() {
		return {
			dialogVisible: false,
			contractDetail: {},
			notaryLink: "",
			videoUrl: "",
		};
	},
	computed: {
		esignFileList() {
			let list = [];
			if (this.contractDetail.esignFileList) {
				list = this.contractDetail.esignFileList;
			}
			return list || [];
		},
		zhzFileList() {
			let list = [];
			if (this.contractDetail.zhzFileList) {
				list = this.contractDetail.zhzFileList;
			}
			return list || [];
		},
		notaryInfo() {
			let info = {};
			if (this.contractDetail.notaryInfo) {
				info = this.contractDetail.notaryInfo;
			}
			return info || {};
		},
		creditInfo() {
			let info = {};
			if (this.contractDetail.creditInfo) {
				info = this.contractDetail.creditInfo;
			}
			return info;
		},
		isShowReport() {
			let show = true;
			const { enableCredit, creditQueryStatus } = this.contractDetail;
			if (!enableCredit) {
				show = true;
			} else if (enableCredit && creditQueryStatus == 11) {
				show = false;
			}
			return show;
		},
		isDisabledNotaryUrl() {
			let status = true;
			if (this.contractDetail.notaryInfo) {
				const { enableNotary, notaryStatus } = this.contractDetail.notaryInfo;
				if (!enableNotary) {
					status = true;
				} else if (enableNotary && notaryStatus == 0) {
					status = false;
				}
			}

			return status;
		},
		isDisabledNotaryFile() {
			let status = true;
			if (this.contractDetail.notaryInfo) {
				const { enableNotary, notaryStatus } = this.contractDetail.notaryInfo;
				if (!enableNotary) {
					status = true;
				} else if (enableNotary && notaryStatus == 5) {
					status = false;
				}
			}

			return status;
		},
	},
	mounted() {
		this.getContractDetail();
	},

	methods: {
		async getContractDetail() {
			this.contractDetail = await getContractDetailAPI(this.$route.params.id);
		},
		// 获取公证链接
		getNotaryUrl() {
			getNotaryApplyAPI(this.$route.params.id).then((res) => {
				this.notaryLink = res.applyUrl;
				this.dialogVisible = true;
			});
		},
		copyLink() {
			this.$copyText(this.notaryLink).then(
				() => {
					this.$message.success("复制成功");
				},
				() => {
					this.$message.error("复制失败");
				}
			);
		},
		// 合同下载
		downloadContract(item) {
			const { fileName, fileUrl } = item;
			this.$confirm(`此操作将下载【${fileName}】, 是否继续?`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					const name = `${
						this.$route.params.id
					}_${fileName}_${dateFormat()}.pdf`;
					downloadPDF(fileUrl, name);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
    // 证书下载
    downloadCertificate(fileUrl) {
      this.$confirm(`此操作将下载该订单公证证书, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            const name = `${this.$route.params.id}_公证证书_${dateFormat()}.pdf`;
            downloadPDF(fileUrl, name);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
    },
		// 证书下载
		downloadNotary() {
			this.$confirm(`此操作将下载该订单公证证书, 是否继续?`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					const name = `${this.$route.params.id}_公证证书_${dateFormat()}.pdf`;
					downloadPDF(this.notaryInfo.notaryCertUrl, name);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
		goCreditReport(e) {
			if (this.isShowReport) {
				this.$alert("征信报告未获取成功、请等待！", "提示", {
					confirmButtonText: "确定",
					callback: (action) => {},
					type: "warning",
				});
				return;
			}

			let link = this.$router.resolve({
				name: `OrderCreditReport`,
				params: { id: this.$route.params.id },
			});
			window.open(link.href, "_blank");
		},
	},
};
</script>

<style lang="scss" scoped>
.top-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
}
.contract-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	span {
		margin: 10px 0;
		&:first-child {
			font-weight: 700;
		}
	}
}
.cert-item {
  margin-top: 20px;
  span {
    margin: 10px 0;
    &:first-child {
      font-weight: 700;
      margin-right: 20px;
    }
  }
}

.contract-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    & > div {
        width: calc((100% - 20px) / 2)
    }
}

.signed-file-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
</style>
